import { ElNotification } from 'element-plus'
import emitter from '@/utils/bus.js';
var lockReconnect = false;  //避免ws重复连接
var ws = null;          // 判断当前浏览器是否支持WebSocket
//心跳检测
var heartCheck = {
    timeout: 60000,        //1分钟发一次心跳
    timeoutObj: null,
    serverTimeoutObj: null,
    reset: function () {
        clearTimeout(this.timeoutObj);
        clearTimeout(this.serverTimeoutObj);
        return this;
    },
    start: function () {
        var self = this;
        this.timeoutObj = setTimeout(function () {
            //这里发送一个心跳，后端收到后，返回一个心跳消息，
            //onmessage拿到返回的心跳就说明连接正常
            ws.send("ping");
            self.serverTimeoutObj = setTimeout(function () {//如果超过一定时间还没重置，说明后端主动断开了
                ws.close();     //如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
            }, self.timeout)
        }, this.timeout)
    }
}
function reconnect(url, time, key) {
    if (lockReconnect) return;
    lockReconnect = true;
    setTimeout(function () {     //没连接上会一直重连，设置延迟避免请求过多
        websocket(url, time, [key]);
        lockReconnect = false;
    }, 2000);
}
function initEventHandle(url, time, key) {
    ws.onopen = function (msg) {
        ws.send("连接成功")
        heartCheck.reset().start();      //心跳检测重置
        console.log('连接成功 :>> ', msg);
    };
    ws.onerror = function (msg) {
        reconnect(url, time, key);
        console.log('连接失败 :>> ', msg);
    };
    ws.onmessage = function (msg) {
        heartCheck.reset().start();      //心跳检测重置
        const Data=JSON.parse(JSON.parse(msg.data).content)
        // ElNotification({
        //     title: '您有新消息',
        //     message: Data.text,
        //     type: 'success'
        // });
        emitter.emit('getAsrContent',{data:Data.text})
    };
    ws.onclose = function () {
        reconnect(url, time, key);
        console.log("webSocket关闭")
    };
}
// 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
window.onbeforeunload = function () {
    ws.close();
}
export function websocket(url, time, key) {
    try {
        if (typeof (WebSocket) === "undefined") {
            console.log("您的浏览器不支持 WebSocket!");
        }
        if ('WebSocket' in window) {
            ws = new WebSocket(process.env.VUE_APP_WEBSOCKET_API + url + time, [key]);
        }
        initEventHandle(url, time, key);
    } catch (e) {
        reconnect(url, time, key);
        console.log(e);
    }
}
