<template>
  <div class="box">
    <div class="header">录音识别功能体验</div>
    <div class="container">
      <div class="left">
        <span class="title">支持格式为.wav、.mp3等，小于5M</span>
        <div class="content">
          <el-upload ref="upload" drag :file-list="fileList" :http-request="httpRequest" action="#" :limit="1"
            :on-exceed="handleExceed" :multiple="false" :auto-upload="false" :show-file-list="false"
            :on-change="handleChange" :before-upload="beforeAvatarUpload">
            <div v-if="!isEmpty" class="upload-first">
              <div>
                <i class="el-icon-upload el-upload__text"></i>
              </div>
              <div :style="{color:'#ff6a00'}">点击上传</div>
            </div>
            <div v-if="isEmpty" class="upload-again" @click.stop="fileList = []">
              <div class="fileName">
                {{fileList[0]?fileList[0].name:''}}
              </div>
              <div>重新上传</div>
            </div>
          </el-upload>
        </div>
      </div>
      <div :class="[{'active':isEmpty},'distinguish']" @click="submitUpload">
        <i v-if="showLoading" :style="{marginRight:'10px'}" class="el-icon-loading"></i>{{showLoading?'正在识别':'开始识别'}}
      </div>
      <div class="right">
        <div class="title">识别结果</div>
        <div class="content">
          <img v-if="!asrCotent" class="img" src="https://img.alicdn.com/tfs/TB1Htp6Z.z1gK0jSZLeXXb9kVXa-500-280.png" />
          {{asrCotent}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAsr } from '../api/common/auth'
import { ElMessage } from 'element-plus'
import { websocket } from '@/utils/asrWs'
import emitter from '@/utils/bus.js'
export default {
  data() {
    return {
      showLoading: false,
      asrCotent: '', //识别结果
      fileList: [], //上传文件
      audioDuration: '', //录音长度
      time: '', //录音长度
      connect: true, //是否正在发送请求
    }
  },
  mounted() {
    this.time = new Date().getTime()
    websocket('/system/ws/', this.time, 'cloud-key')
    emitter.on('getAsrContent', (val) => {
      if (val) {
        this.asrCotent = val.data
        this.showLoading = false
        this.connect = true
      }
    })
  },
  computed: {
    isEmpty() {
      return this.fileList.length == 0 ? false : true
    },
  },
  methods: {
    // 处理上传音频
    submitUpload() {
      if (this.connect) {
        this.$refs.upload.submit() //此处调用的是httpRequest方法
      }
    },
    //上传请求
    async httpRequest(param) {
      this.showLoading = true
      try {
        const form = new FormData()
        form.append('file', param.file)
        form.append('id', this.time)
        let res = await getAsr(form)
        this.connect = false
        if (res.code !== 200) {
          this.showLoading = false
          this.connect = true
          ElMessage({
            type: 'error',
            message: res.message || res.msg,
          })
        }
      } catch (error) {
        this.showLoading = false
        this.connect = true
        console.log('error :>> ', error)
      }
    },
    //选择要上传的文件
    handleChange(file, fileList) {
      this.fileList.push(file)
    },
    //上传数量限制
    handleExceed() {
      this.$message.warning('当前限制选择 1 个文件!')
    },
    //上传前
    beforeAvatarUpload(file) {
      const isAudio = file.type === 'audio/wav' || file.type === 'audio/mpeg'
      const isLt5M = file.size / 1024 / 1024 < 5
      // const isTime60s=this.audioDuration>=60?true:''
      this.getTime(file)
      if (!isAudio) {
        this.$message.error('上传文件只能是 wav/mp3 格式!')
        this.fileList = []
      }
      if (!isLt5M) {
        this.$message.error('上传文件大小不能超过 5MB!')
        this.fileList = []
      }
      return isAudio && isLt5M
    },
    //获取录音长度
    getTime(file) {
      let content = file
      let url = URL.createObjectURL(content)
      let audioElement = new Audio(url)
      audioElement.addEventListener('loadedmetadata', (_event) => {
        this.audioDuration = parseInt(audioElement.duration)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.box {
  height: 100%;
  width: 100%;
  //拖拽
  ::v-deep(.el-upload-dragger) {
    width: 200px;
    height: 120px;
    border: 1px dashed #ff6a00;
  }
  ::v-deep(.el-upload-list__item-name) {
    margin-right: 0;
    margin-top: 35px;
  }
  ::v-deep(.el-upload-list) {
    .is-success {
      display: none;
    }
  }
  ::v-deep(.el-upload) {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
  }
  ::v-deep(.el-upload-dragger .el-icon-upload){
    margin: 0;
  }
  .active {
    background-color: #ff6a00 !important;
  }
  .header {
    width: 92%;
    height: 80px;
    text-align: center;
    font-weight: 550;
    font-size: 20px;
    line-height: 80px;
    margin: 0 auto;
    border-bottom: 2px solid #ebecec;
  }
  .container {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    .distinguish {
      height: 40px;
      width: 120px;
      line-height: 40px;
      margin-left: 20px;
      margin-top: 180px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
      border: 1px solid #ccc;
      background-color: #999999;
    }
    .title {
      font-weight: 550;
      font-size: 16px;
    }
    .content {
      height: 300px;
      width: 700px;
      margin-top: 20px;
      box-sizing: border-box;
      overflow-y: scroll;
      padding: 20px;
      border: 1px solid #ebecec;
      .img {
        height: 140px;
        width: 250px;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        img {
          height: 100%;
          width: 100%;
        }
      }
      .upload-again {
        width: 100%;
        position: absolute;
        top: 30%;
        color: #ff6a00;
        .fileName {
          width: 100%;
          height: 30px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .upload-first {
        height: 100%;
        width: 200px;
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: 20px;
        .el-upload__text {
          font-size: 30px;
          width: 30px;
          color: #ff6a00;
        }
      }
    }
  }
}
</style>
