// 用户管理
import { Request } from '@/http/request'
// 用户菜单页
export function getUserType() {
	return Request.axiosInstance.post('/system/admin-user/user-type')
}
// asr识别录音文件
export function getAsr(parameter: any) {
	return Request.axiosInstance({
		url: '/open/api-asr/test',
		method: 'post',
		headers: { 'Content-Type': 'multipart/form-data' },
		data: parameter
	})
}
export default { getUserType, getAsr }